import {
  Button,
  Table,
  Tabs,
  Avatar,
  Badge,
  message,
  Modal,
  Slider,
  Spin,
  Upload,
  DatePicker,
  Space,
  Input
} from "antd";
import AvatarEditor from "react-avatar-editor";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import TabPane from "antd/lib/tabs/TabPane";
import { dateInDetail, getKenyanDateTime, getMySqlDate } from "utils/Date";
import COLORS from "common/Colors";
import { Col, Row } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { isEmpty, startCase, flatMap, uniq, find } from "lodash";
import moment from "moment";
import { CALL_API } from "common/API";
import { LEAVE_ICON, STATUS_CODE } from "common/Constants";
import { setProfilePic, setUserList } from "redux/actions";
import { bindActionCreators } from "redux";
import MainStyledDropzone from "../pages/Dropzone";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ViewMore from "organisms/ViewMore";
import LeaveTrackerChart from "./LeaveTracker";
import {
  getUserList,
  getClientid,
  getAllDepartmentList,
  getLocations,
  getRoleList,
  getSelectedDepartmentId,
  getUserInfo,
  getLeaveCategoryList,
  getMenuList,
  getUserRoleId,
} from "redux/selectors";
import Icon from "atoms/Icon";
import { ButtonFilled, ButtonOutlined } from "reusableComponent/UIButtons";
import RejectReasonModal from "molecules/RejectReasonModal";
import { useLocation } from "react-router-dom";
import download from "downloadjs";
import EditProfile from "organisms/EditProfile";
import DocumentFolders from "./DocumentFolders";
import Declaration from "./Declaration/Declaration";

const ProfileDetails = ({
  userList,
  ClientId,
  roleList,
  updateUserList,
  selectedDepartmentId,
  leaveCategoryList,
  saveProfilePic,
  userInfo,
  userRoleId,
  menuList,
  departmentList,
  locationList,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [type, setType] = useState("");
  const [docLoader, setDocLoader] = useState(false);
  // const { type } = useParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setType(searchParams.get("type"));
  }, []); // Empty dependency array ensures it's called only once when the component mounts

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const [filteruser, setFilterUser] = useState({});

  const [levelLocationName, setLevelLocationName] = useState("");

  useEffect(() => {
    const filteruser = userList?.find((item) => item?.userid === id);
    setFilterUser(filteruser);

    const filterLevelLocationName = locationList?.find(
      (item) => item.location_id === filteruser?.levellocationid
    );
    setLevelLocationName(filterLevelLocationName?.location_name);
  }, [userList, id, locationList]);

  const [activeTab, setActiveTab] = useState("0");
  const [btnLoader, togglebtnLoader] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [zoomin, setZoomin] = useState(1);
  const [rotatein, setRotatein] = useState(0);
  const [uniqueId, setUniqueId] = useState(generateUniqueId());
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [reasonopen, setReasonOpen] = useState(false);
  const [leaveList, setLeaveList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const havingCancel = false;
  const [leaveDetails, setLeaveDetails] = useState({});
  const [displayDeclineReasonModal, toggleDeclineReasonModalVisibility] =
    useState(false);
  const [EmployeeDetails, setEmployeeDetails] = useState({});
  const [leaveData, setLeaveData] = useState([]);
  const objectUrl = React.useMemo(
    () => (selectedImage ? URL.createObjectURL(selectedImage) : null),
    [selectedImage]
  );
  const [previewURL, setPreviewURL] = useState("");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isLoadingDocuments, toggleLoadingDocuments] = useState(false);
  const [isDocumentUpload, setIsDocumentupload] = useState(false);
  const [previewType, setPrreviewType] = useState("");
  const [fileDetails, setFileDetails] = useState("");
  const [updateState, setUpdateState] = useState("");
  const [terminatedReason, setTerminatedReason] = useState("");

  const handleReasonChange = (e) => {
    setTerminatedReason(e.target.value);
  };

  const editorRef = useRef(null);
  const settingNewCropImage = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();
      const croppedImage = canvas.toDataURL();
      return croppedImage;
    }
  };

  useEffect(() => {
    setEmployeeDetails({
      fullname: filteruser?.fullname || "",
      userid: filteruser?.userid || "",
      roleid: filteruser?.roleid || [],
      rolename: filteruser?.rolename || [],
      start_date: filteruser?.startdate || "",
      gender: filteruser?.gender || "",
      secondaryindustry: filteruser?.secondaryindustry || [],
      profile_url: filteruser?.profileurl || "",
      nssf: filteruser?.nssf || "",
      national_id: filteruser?.national_id || "",
      phoneno: filteruser?.phoneno || "",
      physicaladdress: filteruser?.physicaladdress || "",
      professionalsince: filteruser?.professionalsince || "",
      hireddate: filteruser?.hireddate || "",
      statusname: filteruser?.statusname || "",
      employeesponser: filteruser?.employeesponser || "",
      age: filteruser?.age || "",
      maritalstatus: filteruser?.maritalstatus || "",
      nationality: filteruser?.nationality || "",
      companyname: filteruser?.companyname || "",
      jobtitle: filteruser?.jobtitle || "",
      dateofbirth: filteruser?.dateofbirth
        ? moment(filteruser?.dateofbirth).format("YYYY-MM-DD")
        : "",
      emergencycontactname: filteruser?.emergencycontactname || "",
      emergencycontactnumber: filteruser?.emergencycontactnumber || "",
      baselocationid: filteruser?.baselocationid || "",
      employeeid: filteruser?.employeeid || "",
      firstname: filteruser?.firstname || "",
      lastname: filteruser?.lastname || "",
      salutation: filteruser?.salutation || "",
      competencyQC: filteruser?.competencyQC ? filteruser?.competencyQC : [],
      serviceline: filteruser?.serviceline ? filteruser?.serviceline : [],
      subserviceline: filteruser?.subserviceline
        ? filteruser?.subserviceline
        : [],
      competencyrole: filteruser?.competencyrole
        ? filteruser?.competencyrole
        : [],
      industry: filteruser?.industry ? filteruser?.industry : [],
      sector: filteruser?.sector ? filteruser?.sector : [],
      secondaryIndustry: filteruser?.secondaryindustry
        ? filteruser?.secondaryindustry
        : [],
      industryrole: filteruser?.industryrole ? filteruser?.industryrole : [],
      expertise: filteruser?.expertise || "",
      designation: filteruser?.designation || "",
      grade: filteruser?.grade || "",
      targetchargeability: filteruser?.targetchargeability || "",
      chargeoutrate: filteruser?.chargeoutrate || "",
      greetings: filteruser?.greetings || "",
      lastpromotionlevel: filteruser?.lastpromotionlevel || "",
      dependent: filteruser?.dependent || "",
      educationdetails: filteruser?.educationdetails || [],
      workexperience: filteruser?.workexperience || [],
      professionalcertifications: filteruser?.professionalcertifications || [],
      practicaltrainings: filteruser?.practicaltrainings || [],
      managingoffice: filteruser?.managingoffice || [],
      supervisor: filteruser?.supervisor || "",
      lastpromotion: filteruser?.lastpromotion || "",
      levellocationid: filteruser?.levellocationid || "",
      leveldepartmentname: filteruser?.leveldepartmentname || [],
      regionalsupervisor: filteruser?.regionalsupervisor || "",
      officesupervisor: filteruser?.officesupervisor || "",
      engagementsupervisor: filteruser?.engagementsupervisor || "",
      OfficeSupervisorId: filteruser?.OfficeSupervisorId || "",
      RegionalSupervisorId: filteruser?.RegionalSupervisorId || "",
      status_id: filteruser?.statusid || "",
      country: filteruser?.country || "",
      othernames: filteruser?.othernames || "",
      postaladdress: filteruser?.postaladdress || "",
      workpermitno: filteruser?.workpermitno || "",
      workpermitexpirydate: filteruser?.workpermitexpirydate || "",
      nextofkinrelationship: filteruser?.nextofkinrelationship || "",
      nextofkinpostaladdress: filteruser?.nextofkinpostaladdress || "",
      accountno: filteruser?.accountno || "",
      accountname: filteruser?.accountname || "",
      bankname: filteruser?.bankname || "",
      bankbranch: filteruser?.bankbranch || "",
      bankcountry: filteruser?.bankcountry || "",
      bankcode: filteruser?.bankcode || "",
      bankbranchcode: filteruser?.bankbranchcode || "",
      bankswiftcode: filteruser?.bankswiftcode || "",
      krapin: filteruser?.krapin || "",
      nhif: filteruser?.nhif || "",
      helpmonthlybilling: filteruser?.helpmonthlybilling || "",
      onboardingdate: filteruser?.onboardingdate || "",
      workinghoursfrom: filteruser?.workinghoursfrom || "",
      workinghoursto: filteruser?.workinghoursto || "",
      signature: filteruser?.signature || "",
      OfficeSupervisorId: filteruser?.OfficeSupervisorId || "",
      RegionalSupervisorId: filteruser?.RegionalSupervisorId || "",
      shiftendtime: filteruser?.shiftendtime || "",
      shiftstarttime: filteruser?.shiftstarttime || "",
      grossSalary: filteruser?.grossSalary || "",
      housingAllowance: filteruser?.housingAllowance || "",
      medicalInsurance: filteruser?.medicalInsurance || "",
      pension: filteruser?.pension || "",
      groupLifeInsurance: filteruser?.groupLifeInsurance || "",
      socialSecurityContribution: filteruser?.socialSecurityContribution || "",
      bonus: filteruser?.bonus || "",
      otherCosts: filteruser?.otherCosts || "",
      totalEmploymentCost: filteruser?.totalEmploymentCost || "",
      oversight: filteruser?.oversight || false,
    });
  }, [filteruser]);

  const handleupload = async () => {
    const { code } = await CALL_API(
      `upload-identity/${ClientId}/${filteruser?.userid}`,
      "post",
      {
        name: fileDetails.name,
        type: fileDetails.type,
        content: fileDetails?.content,
        documentName: "identityinformation",
        documentType: fileDetails?.identityName,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      message.success("Document uploaded");
      fetchMasterData();
    } else {
      message.error("You can only upload files up to 10MB in size.");
    }
    setFileDetails("");
    setIsDocumentupload(false);
  };

  useEffect(() => {
    if (fileDetails !== "") {
      handleupload();
    }
  }, [fileDetails]);

  const tabTitles = [
    "Employee Details",
    "Documents",
    "Education Details",
    "Work Experience",
    "Leave Tracker",
    "Declaration",
    "Employee Score",
    "Dynamic Measures",
  ];
  if (userInfo?.userid === "sandeep.khapre@bdo-ea.com" && type === "manage") {
    tabTitles.push("Financials");
  }

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const wrokExperenceColumns = [
    {
      title: "Company name",
      dataIndex: "companyname",
      key: "companyname",
    },
    {
      title: "Job Title",
      dataIndex: "jobtitle",
      key: "jobtitle",
    },
    {
      title: "From",
      dataIndex: "fromdate",
      key: "fromdate",
      render: (fromdate) => {
        if (fromdate) {
          return moment(fromdate).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      title: "To",
      dataIndex: "todate",
      key: "todate",
      render: (todate) => {
        if (todate) {
          return moment(todate).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      title: "Job Description",
      dataIndex: "jobdescription",
      key: "jobdescription",
      className: "normal-column",
      render: (jobdescription) => {
        return <ViewMore value={jobdescription} />;
      },
    },
    {
      title: "Relevant",
      dataIndex: "relevant",
      key: "relevant",
    },
  ];
  const LeaveApprovalColumns = [
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_start_date === "null" ||
          record.new_start_date === null ||
          record.new_start_date === ""
        ) {
          const dateFormat = moment(record.start_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_start_date).format(
            "DD MMM YYYY"
          );
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_end_date === "null" ||
          record.new_end_date === "" ||
          record.new_end_date === null
        ) {
          const dateFormat = moment(record.end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      width: "10%",
    },
    {
      title: "Duration",
      dataIndex: "number_of_days",
      key: "number_of_days",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_number_of_days === "null" ||
          record.new_number_of_days === null ||
          record.new_number_of_days === ""
        ) {
          return `${record.number_of_days} days`;
        } else {
          return `${record.new_number_of_days} days`;
        }
      },
    },
    {
      title: "Description",
      dataIndex: "reason",
      key: "reason",
      width: "30%",
      className: "normal-column",
      render: (reason) => {
        return (
          <>
            <ViewMore value={reason} />
          </>
        );
      },
    },
  ];
  if (
    Menulist?.find(
      (item) => item?.key === "approvals" && item?.permission?.edit
    )
  ) {
    LeaveApprovalColumns.push({
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          <StyledProfileDetails>
            <div className="leave_approval_actions">
              <ButtonOutlined
                onClick={() => {
                  setIsModalOpen(true);
                  viewLeave(record, true);
                }}
              >
                Approve
              </ButtonOutlined>
              <ButtonOutlined
                onClick={() => {
                  toggleDeclineReasonModalVisibility(true);
                  viewLeave(record, true);
                }}
              >
                Decline
              </ButtonOutlined>
            </div>
          </StyledProfileDetails>
        </>
      ),
    });
  }
  const LeaveHistoryColumns = [
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_start_date === "null" ||
          record.new_start_date === "" ||
          record.new_start_date === null
        ) {
          const dateFormat = moment(record.start_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_start_date).format(
            "DD MMM YYYY"
          );
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_end_date === "null" ||
          record.new_end_date === "" ||
          record.new_end_date === null
        ) {
          const dateFormat = moment(record.end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      width: "10%",
    },
    {
      title: "Duration",
      dataIndex: "number_of_days",
      key: "number_of_days",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_number_of_days === "null" ||
          record.new_number_of_days === null ||
          record.new_number_of_days === ""
        ) {
          return `${record.number_of_days} days`;
        } else {
          return `${record.new_number_of_days} days`;
        }
      },
    },
    {
      title: "Description",
      dataIndex: "reason",
      key: "reason",
      width: "30%",
      className: "normal-column",
      render: (reason) => {
        return (
          <>
            <ViewMore value={reason} />
          </>
        );
      },
    },
  ];
  const EducationList = [
    {
      instituteName: filteruser?.institutename || "-",
      degreeDiploma: filteruser?.degree || "-",
      specialization: filteruser?.specialization || "-",
      yearofCompletion: filteruser?.yearofcompletion
        ? moment(filteruser?.yearofcompletion).format("DD-MM-YYYY")
        : "-",
    },
  ];

  const EducationColumns = [
    {
      title: "Institute Name",
      dataIndex: "institutename",
      key: "institutename",
      width: "5%",
    },
    {
      title: "From",
      dataIndex: "fromdate",
      key: "fromdate",
      width: "5%",
      render: (fromdate) => {
        if (fromdate && moment(fromdate).isValid()) {
          return moment(fromdate).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      title: "To",
      dataIndex: "todate",
      key: "todate",
      width: "5%",
      render: (todate) => {
        if (todate && moment(todate).isValid()) {
          return moment(todate).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      title: "Exam Body",
      dataIndex: "exambody",
      key: "exambody",
      width: "5%",
    },
    {
      title: "Awarded",
      dataIndex: "awarded",
      key: "awarded",
      width: "5%",
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      key: "attachment",
      width: "5%",
      render: (attachment, record) => {
        // return <p className="text-info">{attachment[0]?.name}</p>
        if (attachment[0]?.name !== "-") {
          return (
            <button
              className="bg-transparent text-info border-0"
              onClick={() => {
                setPrreviewType(attachment[0]?.name?.split(".")?.pop());
                handlePreviewClick(
                  record?.attachment[0]?.documentId,
                  "educationdetails",
                  attachment[0]?.name
                );
              }}
            >
              {attachment[0]?.name}
            </button>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const handlePreviewClick = async (id, type, document) => {
    // setPrreviewType();
    const preview = document?.split(".")?.pop();
    toggleLoadingDocuments(true);
    const { code, url } = await CALL_API(`document-list/${ClientId}`, "post", {
      method: "preview-user-document",
      documentId: id,
      identityName: type,
      userId: filteruser?.userid,
    });
    if (code === STATUS_CODE.SUCCESS) {
      if (
        preview === "jpg" ||
        preview === "png" ||
        preview === "jpeg" ||
        preview === "svg" ||
        preview === "pdf"
      ) {
        setIsPreviewModalOpen(true);
        setPreviewURL(url);
      } else {
        download(url);
      }
    } else {
      message.error("Something went wrong!! Please try again.");
    }
    toggleLoadingDocuments(false);
  };

  const handleMenuClick = async (id, type, document) => {
    // setPrreviewType();
    setDocLoader(true);
    const preview = document?.split(".")?.pop();
    toggleLoadingDocuments(true);
    const { code, url } = await CALL_API(`document-list/${ClientId}`, "post", {
      method: "preview-identity-document",
      documentId: id,
      identityName: type,
      userId: filteruser?.userid,
    });
    if (code === STATUS_CODE.SUCCESS) {
      if (
        preview === "jpg" ||
        preview === "png" ||
        preview === "jpeg" ||
        preview === "svg" ||
        preview === "pdf"
      ) {
        setIsPreviewModalOpen(true);
        setPreviewURL(url);
      } else {
        download(url);
      }
      setDocLoader(false);
    } else {
      message.error("Something went wrong!! Please try again.");
    }
    toggleLoadingDocuments(false);
  };

  const deleteIdentityInfo = async (id, type) => {
    setIsDocumentupload(true);
    const { code } = await CALL_API(
      `upload-document/${ClientId}/${id}`,
      "delete",
      {
        documentName: type,
        userId: filteruser?.userid,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      message.success("Document has been Deleted");
      fetchMasterData();
    }
    setIsDocumentupload(false);
  };

  const country = filteruser?.country || "Kenya";

  const getDocumentInfo = (type) => ({
    document:
      find(filteruser?.identityinformation, { type })?.documentName || "-",
    documentid:
      find(filteruser?.identityinformation, { type })?.documentId || "-",
  });

  let IdentityInfoList = [
    ...(country !== "Congo"
      ? [
          {
            type: "National ID / Passport Number",
            details: filteruser?.national_id || "-",
            ...getDocumentInfo("nationalid"),
          },
        ]
      : []),
  ];

  let cvList = filteruser?.cv || [];

  const countryDocuments = {
    Kenya: [
      { type: "KRAPIN", details: filteruser?.krapin || "-", label: "KRA PIN" },
      { type: "NHIF", details: filteruser?.nhif || "-", label: "NHIF" },
      { type: "NSSF", details: filteruser?.nssf || "-", label: "NSSF" },
    ],
    Uganda: [
      { type: "TIN", details: filteruser?.tin || "-", label: "TIN" },
      { type: "NSSF", details: filteruser?.nssf || "-", label: "NSSF" },
    ],
    Tanzania: [
      { type: "WCF", details: filteruser?.wcf || "-", label: "WCF" },
      // { type: "NHIF", details: filteruser?.nhif || "-", label: "NHIF" },
      { type: "NSSF", details: filteruser?.nssf || "-", label: "NSSF" },
      { type: "TIN", details: filteruser?.tin || "-", label: "TIN" },
    ],
    Rwanda: [
      { type: "TIN", details: filteruser?.tin || "-", label: "TIN" },
      { type: "RSSB", details: filteruser?.rssb || "-", label: "RSSB" },
    ],
    Ethiopia: [
      {
        type: "Pension",
        details: filteruser?.pension || "-",
        label: "Pension Number",
      },
      { type: "TIN", details: filteruser?.tin || "-", label: "TIN" },
    ],
    Congo: [
      {
        type: "NIF",
        details: filteruser?.nif || "-",
        label: "National ID Number",
      },
      { type: "INSS", details: filteruser?.inss || "-", label: "CNSS Number" },
      { type: "NHI", details: filteruser?.nhi || "-", label: "NIF Tax Number" },
    ],
  };

  countryDocuments[country]?.forEach((doc) => {
    IdentityInfoList.push({
      type: doc.label,
      details: doc.details,
      ...getDocumentInfo(doc?.type?.toLowerCase()),
    });
  });

  const IdentityInfoColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      render: (document, record) => {
        if (document !== "-") {
          return (
            <button
              className="bg-transparent text-info border-0"
              onClick={() => {
                setPrreviewType(document?.split(".")?.pop());
                handleMenuClick(
                  record?.documentid,
                  "identityinformation",
                  document
                );
              }}
            >
              {document}
            </button>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Action",
      dataIndex: "type",
      key: "type",
      render: (type, record) => {
        return (
          <Spin spinning={isDocumentUpload}>
            <Space>
              <Upload
                showUploadList={false}
                customRequest={({ file, onSuccess }) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);

                  reader.onload = () => {
                    const base64Data = reader.result.split(",")[1];

                    onSuccess();
                    setFileDetails({
                      name: file.name,
                      type: file.type,
                      content: base64Data,
                      identityName: type === "NSSF" ? "nssf" : "nationalid",
                    });
                  };

                  reader.onerror = (error) => {
                    console.error("File read error:", error);
                  };
                }}
                onChange={(info) => {
                  if (info.file.status === "done") {
                    // handleFileUpload(info)
                    if (info.fileList.length > 0) {
                      const file = info.fileList[0].originFileObj;

                      const reader = new FileReader();

                      reader.onload = async (event) => {
                        const base64Data = event.target.result;
                        setIsDocumentupload(true);
                        // Log the information
                      };

                      reader.readAsDataURL(file);
                    }
                  } else if (info.file.status === "error") {
                  }
                }}
              >
                <ButtonFilled>
                  {record?.documentid === "-"
                    ? "Attach file"
                    : "Re-attach file"}
                </ButtonFilled>
              </Upload>

              {record?.documentid !== "-" && (
                <ButtonOutlined
                  onClick={() => {
                    deleteIdentityInfo(
                      record?.documentid,
                      "identityinformation"
                    );
                  }}
                >
                  Delete
                </ButtonOutlined>
              )}
            </Space>
          </Spin>
        );
      },
    },
  ];
  const cvInfoColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => name?.split(".").slice(0, -1).join("."),
    },
    {
      title: "Document",
      dataIndex: "name",
      key: "name",
      render: (document, record) => {
        if (document !== "-") {
          return (
            <button
              className="bg-transparent text-info border-0"
              onClick={() => {
                setPrreviewType(document?.split(".")?.pop());
                handleMenuClick(record?.documentId, "cv", document);
              }}
            >
              {document}
            </button>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const locationOfWorkData = [
    {
      wfhome: "0%",
      wfoffice: "0%",
      wfabroad: "0%",
      wfclient: "0%",
    },
  ];

  const locationOfWorkColumns = [
    {
      title: "WF Home",
      dataIndex: "wfhome",
      key: "wfhome",
    },
    {
      title: "WF Office",
      dataIndex: "wfoffice",
      key: "wfoffice",
    },
    {
      title: "WF Abroad",
      dataIndex: "wfabroad",
      key: "wfabroad",
    },
    {
      title: "WF Client",
      dataIndex: "wfclient",
      key: "wfclient",
    },
  ];

  const ApproveOk = async (reason) => {
    return new Promise(async (resolve) => {
      await approveRejectCancelLeave(5, reason, havingCancel);
      resolve();
    }).catch((err) => console.log("[ApproveOk] Error-->", err));
  };

  const approveHandleCancel = () => {
    setIsModalOpen(false);
  };

  const approveHandleOk = () => {
    setLoading(true);
    ApproveOk();
  };

  const viewLeave = (leaveObject) => {
    setLeaveDetails({
      ...leaveObject,
    });
  };

  const closeDeclineReasonModal = async (reason) => {
    if (reason) await approveRejectCancelLeave("6", reason);
    toggleDeclineReasonModalVisibility(false);
  };
  // Note : 3rd params needed
  const approveRejectCancelLeave = async (
    statusId,
    declined_reason = "",
    is_leave_cancel_request
  ) => {
    return new Promise(async (resolve) => {
      const { code } = await CALL_API(
        `approve-reject-cancel-leave/${ClientId}`,
        "post",
        {
          departmentId: selectedDepartmentId,
          user_leave_id: leaveDetails?.user_leave_id,
          status_id: statusId.toString(),
          declined_reason,
          is_cancel_request:
            leaveDetails.having_cancel_request === "1" ? true : false,
          start_date: leaveDetails?.new_start_date
            ? getMySqlDate(leaveDetails?.new_start_date)
            : getMySqlDate(leaveDetails?.start_date),
          end_date: leaveDetails?.new_end_date
            ? getMySqlDate(leaveDetails?.new_end_date)
            : getMySqlDate(leaveDetails?.end_date),
        }
      );

      if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
        message.error(`Oops!! something went wrong.`);
      } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
        message.error(`Invalid payload. Please enter correct data.`);
      } else if (code === STATUS_CODE.RECORD_EXIST) {
        message.error(`Leave aleady exist.`);
      } else if (code === STATUS_CODE.SUCCESS) {
        message.success(`Your leave application has been Approved.`);
        getLeaveList();
      }
      resolve();
      setLoading(false);
      setIsModalOpen(false);
    });
  };

  const LeaveApprovalData = leaveList.filter((val) => {
    if (
      val?.status_id === "4" ||
      (val?.status_id === "5" && val?.having_cancel_request === "1")
    ) {
      return val;
    }
  });

  const LeaveHistoryData = leaveList.filter((val) => {
    if (
      val?.status_id === "5" &&
      (val?.having_cancel_request === "0" || val?.having_cancel_request === "")
    ) {
      return val;
    }
  });

  useEffect(() => {
    getLeaveList();
  }, []);

  const getLeaveList = async () => {
    const { leaves = [] } = await CALL_API(`leave-list/${ClientId}`, "post", {
      status_id: "",
      department_id: selectedDepartmentId,
      selected_role_id: 2,
      start_date: "",
      end_date: "",
      user_id: id,
    });
    const filteredLeaves = leaves.filter((item) => item.userid === id);
    setLeaveList(filteredLeaves.reverse());
  };

  const getUserLeaveData = async () => {
    setLoading(true);
    try {
      const response = await CALL_API(
        `employee-leave-details/${ClientId}`,
        "post",
        {
          year: dateInDetail(getKenyanDateTime()).year,
          userId: id,
        }
      );
      if (!isEmpty(response)) {
        setLeaveData(response);
      }
    } catch (error) {
      console.error("Error fetching user leave data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserLeaveData();
  }, [ClientId]);

  const ComplianceStatus = [
    {
      key: "Learning Hours Completed",
      value: "0 hr",
    },
    {
      key: "Contacts Generated",
      value: "Nos. & Value",
    },
    {
      key: "Leads Generated",
      value: "Nos. & Value",
    },
    {
      key: "Proposal Written",
      value: "Nos. & Value",
    },
    {
      key: "Proposals Won",
      value: "Nos. & Value",
    },
  ];

  const breadStyle = { color: "#636980", cursor: "pointer" };

  const breadcrumbs = [
    <label key="2" style={breadStyle} onClick={() => history.goBack()}>
      {type === "team" ? "My Team" : "People"}
    </label>,
    <Typography key="3" color="text.primary">
      {filteruser?.fullname}
    </Typography>,
  ];
  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      updateUserList(userList);
      let profileUpdate = userList?.filter(
        (val) => val?.userid === userInfo.userid
      );
      if (profileUpdate?.length !== 0) {
        saveProfilePic(profileUpdate[0]?.profileurl);
      }
    }
  };
  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  const deleteEmployee = async () => {
    togglebtnLoader(true);
    await CALL_API(`add-users/${ClientId}`, "delete", {
      user_id: id,
      status_id: "0",
      status_name: "inactive",
      terminated_date: moment(new Date()).format('YYYY-MM-DD'),
      terminated_reason: terminatedReason      
    });
    togglebtnLoader(false);

      updateUserList(userList.map((user) => {
        if (user.userid === id) {
          return {
            ...user,
            statusid: "0",
            statusname: "inactive"
          };
        }
        return user;
      }
      ))
    
    fetchMasterData();
    message.success(
      "This Employee has been terminated.This employee will be removed within a maximum of 8 hours"
    );
    history.goBack();
  };

  const reinstateEmployee = async () => {
    togglebtnLoader(true);
    await CALL_API(`add-users/${ClientId}`, "delete", {
      user_id: id,
      status_id: "1",
      status_name: "active",
      terminated_date:"",
      terminated_reason:""
    });

    updateUserList(userList.map((user) => {
      if (user.userid === id) {
        return {
          ...user,
          statusid: "1",
          statusname: "active"
        };
      }
      return user;
    }
    ))
    
    togglebtnLoader(false);
    fetchMasterData();
    message.success("This Employee has been reinstate");
    history.goBack();
  };
  function generateUniqueId() {
    const usedIds = [];
    let uniqueId = "";
    while (uniqueId.length !== 6) {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      if (!usedIds.includes(randomNumber.toString())) {
        usedIds.push(randomNumber.toString());
        uniqueId += randomNumber.toString();
      }
    }
    return uniqueId;
  }

  let userId = id;
  let splitUserId = userId.split("@");
  let userIdWithoutDomain = splitUserId[0];
  let updatedUserId = userIdWithoutDomain + uniqueId;

  const handleUploadProfile = async () => {
    let x = await settingNewCropImage();
    try {
      setUniqueId(generateUniqueId());
      const obj = {
        userid: updatedUserId,
        filetype: selectedImage.type,
        content: x,
        filename: uniqueId,
        emailid: id,
      };
      setConfirmLoading(true);
      const { code } = await CALL_API(
        `profile-upload/${ClientId}`,
        "post",
        obj
      );

      if (code === "SUCCESS") {
        setShowImageModal(false);
        fetchMasterData();
        setConfirmLoading(false);
      }
      setConfirmLoading(false);
    } catch (error) {
      console.error("[handleUploadProfile] Error -->", error);
      setConfirmLoading(false);
    }
  };

  const EducationDetails = [
    {
      data: "Institute Name",
      value: filteruser?.institutename || "-",
    },
    {
      data: "Educational Degree & Status",
      value:
        typeof filteruser?.degree === "string"
          ? filteruser?.degree
            ? [filteruser?.degree]
            : "-"
          : filteruser?.degree?.join(", ") || "-",
    },
    {
      data: "Professional Certification & Status",
      value:
        typeof filteruser?.professionalcertification === "string"
          ? filteruser?.professionalcertification
            ? [filteruser?.professionalcertification]
            : "-"
          : filteruser?.professionalcertification?.join(", ") || "-",
    },
    {
      data: "Specialization",
      value: filteruser?.specialization || "-",
    },
    {
      data: "Year of Completion",
      value: filteruser?.yearofcompletion
        ? moment(filteruser?.yearofcompletion).format("DD-MM-YYYY")
        : "-",
    },
  ];

  const VtpDetails = [
    {
      data: "Lead Response",
      value: 5,
    },
    {
      data: "Complaint Response",
      value: 4,
    },
    {
      data: "Internal assignment Response",
      value: 5,
    },
    {
      data: "Client Assignment Response",
      value: 3,
    },
  ];

  const formatNumber = (value) => {
    if (!value) return "0";
    const number = parseFloat(value.toString().replace(/,/g, ""));
    return number.toLocaleString();
  };

  const SalaryAndBenefits = [
    {
      data: "Gross Salary",
      value: `$ ${formatNumber(
        filteruser?.grossSalary ? filteruser?.grossSalary : 0
      )}`,
    },
    {
      data: "Housing Allowance",
      value: `$ ${formatNumber(
        filteruser?.housingAllowance ? filteruser?.housingAllowance : 0
      )}`,
    },
    {
      data: "Medical Insurance",
      value: `$ ${formatNumber(
        filteruser?.medicalInsurance ? filteruser?.medicalInsurance : 0
      )}`,
    },
    {
      data: "Pension",
      value: `$ ${formatNumber(filteruser?.pension ? filteruser?.pension : 0)}`,
    },
    {
      data: "Group Life Insurance",
      value: `$ ${formatNumber(
        filteruser?.groupLifeInsurance ? filteruser?.groupLifeInsurance : 0
      )}`,
    },
    {
      data: "Social Security contribution (employer)",
      value: `$ ${formatNumber(
        filteruser?.socialSecurityContribution
          ? filteruser?.socialSecurityContribution
          : 0
      )}`,
    },
    {
      data: "Bonus",
      value: `$ ${formatNumber(filteruser?.bonus ? filteruser?.bonus : 0)}`,
    },
    {
      data: "Other Costs",
      value: `$ ${formatNumber(
        filteruser?.otherCosts ? filteruser?.otherCosts : 0
      )}`,
    },
    {
      data: "Total Employment cost ",
      value: `$ ${formatNumber(
        filteruser?.totalEmploymentCost ? filteruser?.totalEmploymentCost : 0
      )}`,
    },
  ];

  const bankInfo = [
    {
      data: "Account Name",
      value: filteruser?.accountname || "-",
    },
    {
      data: "Account Number",
      value: filteruser?.accountno || "-",
    },
    {
      data: "Bank Name",
      value: filteruser?.bankname || "-",
    },
    {
      data: "Branch",
      value: filteruser?.bankbranch || "-",
    },
    {
      data: "Country",
      value: filteruser?.bankcountry || "-",
    },
    {
      data: "Bank code",
      value: filteruser?.bankcode || "-",
    },
    {
      data: "Branch Code",
      value: filteruser?.bankbranchcode || "-",
    },
    {
      data: "Swiftcode",
      value: filteruser?.bankswiftcode || "-",
    },
    {
      data: "KRA PIN Number",
      value: filteruser?.krapin || "-",
    },
    {
      data: "NSSF Number",
      value: filteruser?.nssf || "-",
    },
    {
      data: "NHIF Number",
      value: filteruser?.nhif || "-",
    },
    {
      data: "HELB monthly billing (KES)",
      value: filteruser?.helpmonthlybilling || "-",
    },
  ];

  const ExampleGroupDetails = [
    {
      data: "Disbursement/ Claims Made",
      value: "$0",
    },
    {
      data: "Actual Chargeability%",
      value: "0%",
    },
    {
      data: "Actual Fees generated",
      value: "$0",
    },
    {
      data: "Adj Chargeability%",
      value: "0%",
    },
    {
      data: "Adjusted Fees",
      value: "$0",
    },
  ];

  const TimeSpentDetails = [
    {
      data: "Business development",
      value: "0 hr",
    },
    {
      data: "Training",
      value: "0 hr",
    },
    {
      data: "Admin",
      value: "0 hr",
    },
    {
      data: "Bench Time",
      value: "0 hr",
    },
    {
      data: "Travel",
      value: "0 hr",
    },
    {
      data: "leave",
      value: "0 hr",
    },
  ];

  const EmployeeScore = [
    {
      data: "Annual Appraisal Score:",
      value: 5,
    },
    {
      data: "Engagement Appraisal score:",
      value: 4,
    },
    {
      data: "Quality Score:",
      value: 5,
    },
    {
      data: "Leadership score:",
      value: 3,
    },
    {
      data: "Employee 360 score:",
      value: 3,
    },
    {
      data: "Star Status/ Wow factor:",
      value: 3,
    },
    {
      data: "Avg Client Satisfaction",
      value: 3,
    },
  ];

  const WorkInfoDetails = [
    // {
    //   data: "Overtime Eligible",
    //   value: filteruser?.overtimestatus || "-",
    // },
    // {
    //   data: "Overtime Eligible",
    //   value: filteruser?.overtimestatus || "-",
    // },
    {
      data: "Contracting Company",
      value: filteruser?.companyname || "-",
    },
    {
      data: "Primary Industry",
      value:
        typeof filteruser?.industry === "string"
          ? filteruser?.industry
            ? [filteruser?.industry]
            : "-"
          : filteruser?.industry?.join(", ") || "-",
    },
    {
      data: "Secondary Industry",
      value:
        typeof filteruser?.secondaryindustry === "string"
          ? filteruser?.secondaryindustry
            ? [filteruser?.secondaryindustry]
            : "-"
          : filteruser?.secondaryindustry?.join(", ") || "-",
    },
    {
      data: "Sector",
      value:
        typeof filteruser?.sector === "string"
          ? filteruser?.sector
            ? [filteruser?.sector]
            : "-"
          : filteruser?.sector?.join(", ") || "-",
    },
    {
      data: "Expertise",
      value: filteruser?.expertise || "-",
    },
    {
      data: "Industry Role",
      value:
        typeof filteruser?.industryrole === "string"
          ? filteruser?.industryrole
            ? [filteruser?.industryrole]
            : "-"
          : filteruser?.industryrole?.join(", ") || "-",
    },
    {
      data: "Regional Competency Supervisor",
      value: filteruser?.regionalsupervisor || "-",
    },
    {
      data: "Office Competency Supervisor",
      value: filteruser?.officesupervisor || "-",
    },
    {
      data: "Secondary Supervisor",
      value: filteruser?.engagementsupervisor,
    },
    {
      data: "Designation",
      value: filteruser?.designation || "-",
    },
    {
      data: "Grade",
      value: filteruser?.grade || "-",
    },
    {
      data: "Target Chargeability %",
      value: filteruser?.targetchargeability || "-",
    },
    {
      data: "Charge Out Rate",
      value: filteruser?.chargeoutrate || "-",
    },
    {
      data: "Last Promotion Date",
      value: filteruser?.lastpromotionlevel
        ? moment(filteruser?.lastpromotionlevel).format("DD-MM-YYYY")
        : "-",
    },
    {
      data: "Last Promotional Level",
      value: filteruser?.lastpromotion ? filteruser?.lastpromotion : "-",
    },
    {
      data: "Professional Since",
      value: filteruser?.professionalsince
        ? moment(filteruser?.professionalsince).format("DD-MM-YYYY")
        : "-",
    },
    {
      data: "Hired Date",
      value: filteruser?.hireddate
        ? moment(filteruser?.hireddate).format("DD-MM-YYYY")
        : "-",
    },
    {
      data: "Job Title",
      value: filteruser?.jobtitle || "-",
    },
    {
      data: "Status",
      value: filteruser?.statusid === "1" ? "Active" : "In Active" || "-",
    },
    {
      data: "Employee Sponsor",
      value: filteruser?.employeesponser || "-",
    },
  ];
  const LevelsDetails = [
    {
      data: "Country",
      value:
        typeof filteruser?.managingoffice === "string"
          ? filteruser?.managingoffice
            ? [filteruser?.managingoffice]
            : "-"
          : filteruser?.managingoffice?.join(", ") || "-",
    },
    {
      data: "Competency/ QC",
      value:
        typeof filteruser?.competencyQC === "string"
          ? filteruser?.competencyQC
            ? [filteruser?.competencyQC]
            : "-"
          : filteruser?.competencyQC?.join(", ") || "-",
    },
    {
      data: "Service Line",
      value:
        typeof filteruser?.serviceline === "string"
          ? filteruser?.serviceline
            ? [filteruser?.serviceline]
            : "-"
          : filteruser?.serviceline?.join(", ") || "-",
    },
    {
      data: "Sub Serviceline",
      value:
        typeof filteruser?.subserviceline === "string"
          ? filteruser?.subserviceline
            ? [filteruser?.subserviceline]
            : "-"
          : filteruser?.subserviceline?.join(", ") || "-",
    },
    {
      data: "Office Competency Role",
      value:
        typeof filteruser?.competencyrole === "string"
          ? filteruser?.competencyrole.length
            ? [filteruser?.competencyrole]
            : "-"
          : filteruser?.competencyrole?.join(", ") || "-",
    },
    {
      data: "Location",
      value: levelLocationName || "-",
    },
    {
      data: "Department",
      value: filteruser?.leveldepartmentname || "-",
    },
    {
      data: "Over Sight ",
      value: filteruser?.oversight ? "Yes" : "No",
    },
    // {
    //   data: "Supervisor / Manager",
    //   value: filteruser?.supervisor || "-",
    // },
  ];
  const personalDet = [
    {
      data: "First Name",
      value: filteruser?.firstname || "-",
    },
    {
      data: "Last Name",
      value: filteruser?.lastname || "-",
    },
    {
      data: "Salutation",
      value: filteruser?.salutation || "-",
    },
    {
      data: "Other names",
      value: filteruser?.othernames || "-",
    },
    {
      data: "Date Of Birth",
      value: filteruser?.dateofbirth
        ? moment(filteruser?.dateofbirth).format("DD-MM-YYYY")
        : "-",
    },
    {
      data: "Gender",
      value: filteruser?.gender
        ? filteruser?.gender.charAt(0).toUpperCase() +
          filteruser?.gender.slice(1)
        : "-",
    },
    {
      data: "No. of Dependants",
      value: filteruser?.dependent || "-",
    },
    {
      data: "Marital Status",
      value: filteruser?.maritalstatus
        ? filteruser?.maritalstatus.charAt(0).toUpperCase() +
          filteruser?.maritalstatus.slice(1)
        : "-",
    },
    {
      data: "Phone No",
      value: filteruser?.phoneno || "-",
    },
    {
      data: "Email Address",
      value: filteruser?.userid || "-",
    },
    {
      data: "Postal Address",
      value: filteruser?.postaladdress || "-",
    },
    {
      data: "Physical Address",
      value: filteruser?.physicaladdress || "-",
    },
    {
      data: "Country",
      value: filteruser?.country || "-",
    },
    {
      data: "Greetings",
      value: filteruser?.greetings || "-",
    },
    {
      data: "Nationality",
      value: filteruser?.nationality
        ? filteruser?.nationality.charAt(0).toUpperCase() +
          filteruser?.nationality.slice(1)
        : "-",
    },
    {
      data: "Employee ID",
      value: filteruser?.employeeid || "-",
    },
    {
      data: "National ID",
      value: filteruser?.national_id || "-",
    },
    {
      data: "Age",
      value: filteruser?.age || "-",
    },
    {
      data: "Work Permit No",
      value: filteruser?.workpermitno || "-",
    },
    {
      data: "Work permit expiry date",
      value: filteruser?.workpermitexpirydate || "-",
    },
  ];

  const nextOfKinDetails = [
    {
      data: "Emergency Contact Name",
      value: filteruser?.emergencycontactname || "-",
    },
    {
      data: "Emergency Contact Number",
      value: filteruser?.emergencycontactnumber || "-",
    },
    {
      data: "Relationship",
      value: filteruser?.nextofkinrelationship || "-",
    },
    {
      data: "Postal Address",
      value: filteruser?.nextofkinpostaladdress || "-",
    },
  ];

  const PersenalcertificationColumns = [
    {
      title: "Certification Type",
      dataIndex: "certificationtype",
      key: "certificationtype",
      width: "5%",
    },
    {
      title: "From",
      dataIndex: "fromdate",
      key: "fromdate",
      width: "5%",
      render: (fromdate) => {
        if (fromdate) {
          return moment(fromdate).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      title: "To",
      dataIndex: "todate",
      key: "todate",
      width: "5%",
      render: (todate) => {
        if (todate) {
          return moment(todate).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: "5%",
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      key: "attachment",
      width: "5%",
      render: (attachment, record) => {
        // return <p className="text-info">{attachment[0]?.name}</p>
        if (attachment[0]?.name !== "-") {
          return (
            <button
              className="bg-transparent text-info border-0"
              onClick={() => {
                setPrreviewType(attachment[0]?.name?.split(".")?.pop());
                handlePreviewClick(
                  record?.attachment[0]?.documentId,
                  "professionalcertifications",
                  attachment[0]?.name
                );
              }}
            >
              {attachment[0]?.name}
            </button>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const PracticalTrainingColumns = [
    {
      title: "Organisation Name",
      dataIndex: "organisationname",
      key: "organisationname",
    },
    {
      title: "From",
      dataIndex: "fromdate",
      key: "fromdate",

      render: (fromdate) => {
        if (fromdate) {
          return moment(fromdate).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      title: "To",
      dataIndex: "todate",
      key: "todate",

      render: (todate) => {
        if (todate) {
          return moment(todate).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      title: "Position Held",
      dataIndex: "positionheld",
      key: "positionheld",
    },
    {
      title: "Nature of work & Tasks performed or completed",
      dataIndex: "tasksperformed",
      key: "tasksperformed",

      className: "normal-column",
      render: (tasksperformed) => {
        return <ViewMore value={tasksperformed} count={20} />;
      },
    },
  ];

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedImage(file);
  };
  const CropPopModal = () => {
    setShowImageModal(true);
  };
  const CloseImageModal = async () => {
    setShowImageModal(false);
    setZoomin(1);
    setRotatein(0);
  };
  const resetSelectedImage = () => {
    setSelectedImage(null);
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    deleteEmployee();
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const showReasonModal = () => {
    setReasonOpen(true);
  };
  const handleReasonOk = () => {
    setReasonOpen(false);
    setOpen(true);
  };
  const handleReasonCancel = () => {
    setReasonOpen(false);
  };


  const role = roleList?.filter((item) =>
    filteruser?.roleid?.includes(item?.roleId)
  );

  const concatenatedArray = flatMap(filteruser?.userdepartmentid);
  const uniqueArray = uniq(concatenatedArray);
  const department = departmentList?.filter((item) =>
    uniqueArray?.includes(item?.department_id)
  );

  const filteredCategoriesbygender =
    filteruser?.gender?.toLowerCase() === "male"
      ? leaveCategoryList?.filter(
          (item) => item.eligible?.toLowerCase() !== "female"
        )
      : leaveCategoryList?.filter(
          (item) => item.eligible?.toLowerCase() !== "male"
        );
  const cursorstyle = { cursor: "pointer" };
  const handleEmailClick = (id) => {
    window.open(`mailto:${id}`);
  };
  return (
    <>
      {!updateState && (
        <>
          <StyledProfileDetails>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
            <Row className="Broker_profile_flex">
              <Col xl={8}>
                <Col className="styled_col">
                  {type === "hr" && (
                    <>
                      {filteruser?.profileurl === null ||
                      filteruser?.profileurl === "" ||
                      filteruser?.profileurl === "null" ? (
                        <>
                          <Badge
                            count={
                              Menulist?.find(
                                (item) =>
                                  item?.key === "HRResources" &&
                                  item?.subMenu?.find(
                                    (subItem) =>
                                      subItem?.key === "team" &&
                                      subItem?.permission.edit
                                  )
                              ) && (
                                <Icon
                                  style={cursorstyle}
                                  name="profileEdit"
                                  className="camera_select_icon"
                                  onClick={CropPopModal}
                                />
                              )
                            }
                          >
                            <StyledAvatar size="default">
                              {startCase(filteruser.fullname).charAt(0)}
                            </StyledAvatar>
                          </Badge>
                        </>
                      ) : (
                        <>
                          <Badge
                            count={
                              Menulist?.find(
                                (item) =>
                                  item?.key === "HRResources" &&
                                  item?.subMenu?.find(
                                    (subItem) =>
                                      subItem?.key === "team" &&
                                      subItem?.permission.edit
                                  )
                              ) && (
                                <Icon
                                  style={cursorstyle}
                                  name="profileEdit"
                                  className="camera_select_icon"
                                  onClick={CropPopModal}
                                />
                              )
                            }
                          >
                            <StyledAvatar
                              src={filteruser?.profileurl}
                              size="default"
                              className="StyledImage"
                            ></StyledAvatar>
                          </Badge>
                        </>
                      )}
                    </>
                  )}
                  {type === "manage" && (
                    <>
                      {filteruser?.profileurl === null ||
                      filteruser?.profileurl === "" ? (
                        <>
                          <Badge
                            count={
                              Menulist?.find(
                                (item) =>
                                  item?.key === "employee" &&
                                  item?.permission.edit
                              ) && (
                                <Icon
                                  style={cursorstyle}
                                  name="profileEdit"
                                  className="camera_select_icon"
                                  onClick={CropPopModal}
                                />
                              )
                            }
                          >
                            <StyledAvatar size="default">
                              {startCase(filteruser.fullname).charAt(0)}
                            </StyledAvatar>
                          </Badge>
                        </>
                      ) : (
                        <>
                          <Badge
                            count={
                              Menulist?.find(
                                (item) =>
                                  item?.key === "employee" &&
                                  item?.permission.edit
                              ) && (
                                <Icon
                                  style={cursorstyle}
                                  name="profileEdit"
                                  className="camera_select_icon"
                                  onClick={CropPopModal}
                                />
                              )
                            }
                          >
                            <StyledAvatar
                              src={filteruser?.profileurl}
                              size="default"
                              className="StyledImage"
                            ></StyledAvatar>
                          </Badge>
                        </>
                      )}
                    </>
                  )}
                  {type === "team" && (
                    <>
                      {filteruser?.profileurl === null ||
                      filteruser?.profileurl === "" ||
                      filteruser?.profileurl === "null" ? (
                        <>
                          <Badge>
                            <StyledAvatar size="default">
                              {startCase(filteruser.fullname).charAt(0)}
                            </StyledAvatar>
                          </Badge>
                        </>
                      ) : (
                        <>
                          <Badge>
                            <StyledAvatar
                              src={filteruser?.profileurl}
                              size="default"
                              className="StyledImage"
                            ></StyledAvatar>
                          </Badge>
                        </>
                      )}
                    </>
                  )}

                  <div className="w-100 bg-red d-flex justify-content-between">
                    <div>
                      <div className="px-3">
                        <div className="">
                          <p className="m-0 broker_tittle">
                            {filteruser?.fullname || "-"}
                          </p>
                        </div>
                        <div className="contact_details text-wrap">
                          {/* <p className="common_color_style">Role: </p>
                          <p className="w-100 common_color_style">
                            {" "}
                            {role?.map((item) => item?.roleName)?.join(",")}
                          </p> */}
                          <p className="common_color_style">Designation: </p>
                          <p className="w-100 common_color_style">
                            {filteruser?.designation || "-"}
                          </p>
                        </div>
                        <div className="contact_details">
                          <div className="common_color_style">Department: </div>
                          <div className="w-100 common_color_style text-wrap">
                            {" "}
                            {
                              find(LevelsDetails, { data: "Country" }).value
                            } |{" "}
                            {
                              find(LevelsDetails, { data: "Competency/ QC" })
                                .value
                            }{" "}
                            |{" "}
                            {
                              find(LevelsDetails, { data: "Service Line" })
                                .value
                            }
                          </div>
                        </div>
                        <div className="contact_details">
                          <p className="common_color_style">
                            Employee ID:{" "}
                            <span className="common_color_style">
                              {filteruser?.employeeid || "-"}
                            </span>
                          </p>
                        </div>
                        <p className="common_color_style">
                          Email ID:{" "}
                          <span
                            className="common_color_style"
                            style={cursorstyle}
                            onClick={() => handleEmailClick(id)}
                          >
                            {id || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Col>
              {type === "hr" && (
                <Col xl={4} className="credit_box_container">
                  {Menulist?.find(
                    (item) =>
                      item?.key === "HRResources" &&
                      item?.subMenu?.find(
                        (subItem) =>
                          subItem?.key === "team" && subItem?.permission.delete
                      )
                  ) && (
                    <>
                      {filteruser?.statusid?.toString() === "1" && (
                        <div>
                          <ButtonOutlined onClick={showModal}>
                            <Icon
                              name="terminate"
                              className="Remove_select_icon"
                            />
                            Terminate
                          </ButtonOutlined>
                        </div>
                      )}
                      {filteruser?.statusid?.toString() === "0" && (
                        <div>
                          <ButtonOutlined
                            onClick={reinstateEmployee}
                            loading={btnLoader}
                          >
                            Reinstate
                          </ButtonOutlined>
                        </div>
                      )}
                    </>
                  )}
                </Col>
              )}
              {type === "manage" && (
                <Col xl={4} className="credit_box_container">
                  {Menulist?.find(
                    (item) => item?.key === "employee" && item?.permission.edit
                  ) && (
                    <>
                      {filteruser?.statusid?.toString() === "1" && (
                        <div>
                          <ButtonOutlined onClick={showReasonModal}>
                            <Icon
                              name="terminate"
                              className="Remove_select_icon"
                            />
                            Terminate
                          </ButtonOutlined>
                        </div>
                      )}
                      {filteruser?.statusid?.toString() === "0" && (
                        <div>
                          <ButtonOutlined
                            onClick={reinstateEmployee}
                            loading={btnLoader}
                          >
                            Reinstate
                          </ButtonOutlined>
                        </div>
                      )}
                    </>
                  )}
                </Col>
              )}
            </Row>
            <Tabs
              defaultActiveKey={activeTab}
              className="mt-3"
              onChange={handleTabChange}
            >
              {tabTitles.map((title, index) => (
                <TabPane tab={title} key={index.toString()}>
                  {activeTab === "0" && (
                    <>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <p className="employee_detail_headings mb-0">Level:</p>
                        {type === "manage" &&
                          Menulist?.find(
                            (item) =>
                              item?.key === "employee" && item?.permission.edit
                          ) && (
                            <ButtonFilled
                              className="px-1"
                              onClick={() => setUpdateState("basic")}
                            >
                              <Icon name="edit"></Icon>
                            </ButtonFilled>
                          )}

                        {type === "hr" &&
                          Menulist?.find(
                            (item) =>
                              item?.key === "HRResources" &&
                              item?.subMenu?.find(
                                (subItem) =>
                                  subItem?.key === "team" &&
                                  subItem?.permission.edit
                              )
                          ) && (
                            <ButtonFilled
                              className="px-1"
                              onClick={() => setUpdateState("basic")}
                            >
                              <Icon name="edit"></Icon>
                            </ButtonFilled>
                          )}
                      </div>
                      <Row className="employee_work_info_box">
                        {LevelsDetails.map((data, index) => {
                          return (
                            <Col xs={12} lg={6} key={index}>
                              <StyledRow className="my-1 employee_work_info">
                                <Col sm={12} md={6} className="my-1 text-wrap">
                                  <b className="label_Color">{data.data}: </b>
                                </Col>
                                <Col
                                  sm={12}
                                  md={6}
                                  className="my-1 employess_detail_values text-wrap"
                                >
                                  <b className="label_value_Color">
                                    {data.value}
                                  </b>
                                </Col>
                              </StyledRow>
                            </Col>
                          );
                        })}
                      </Row>
                      <br />

                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <p className="employee_detail_headings mb-0">
                          Work Information:
                        </p>
                        {/* {type === "manage" && Menulist?.find(
                                (item) =>
                                  item?.key === "manage" &&
                                  item?.subMenu?.find(
                                    (subItem) =>
                                      subItem?.key === "employee" &&
                                      subItem?.permission.edit
                                  )
                              ) && <ButtonFilled
                            className="px-1"
                            onClick={()=>setUpdateState("workinformation")}
                          >
                            <Icon name="edit"></Icon>
                          </ButtonFilled>
} */}
                      </div>
                      <Row className="employee_work_info_box">
                        {WorkInfoDetails.map((data, index) => {
                          return (
                            <Col xs={12} lg={6} key={index}>
                              <StyledRow className="my-1 employee_work_info">
                                <Col
                                  sm={12}
                                  md={6}
                                  className={`my-1 ${
                                    data.data ===
                                      "Office Competency Supervisor" ||
                                    data.data ===
                                      "Regional Competency Supervisor"
                                      ? "text-wrap"
                                      : "text-nowrap"
                                  }`}
                                >
                                  <b className="label_Color">{data.data}: </b>
                                </Col>
                                <Col
                                  sm={12}
                                  md={6}
                                  className="my-1 employess_detail_values text-wrap"
                                >
                                  <b className="label_value_Color">
                                    {data.value}
                                  </b>
                                </Col>
                              </StyledRow>
                            </Col>
                          );
                        })}
                      </Row>
                      <br />
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <p className="employee_detail_headings mb-0">
                          Personal Information:
                        </p>
                        {/* {type === "manage" && Menulist?.find(
                                (item) =>
                                  item?.key === "manage" &&
                                  item?.subMenu?.find(
                                    (subItem) =>
                                      subItem?.key === "employee" &&
                                      subItem?.permission.edit
                                  )
                              ) && <ButtonFilled
                            className="px-1"
                            onClick={()=>setUpdateState("personalinformation")}
                          >
                            <Icon name="edit"></Icon>
                          </ButtonFilled>} */}
                      </div>
                      <Row className="employee_work_info_box">
                        {personalDet.map((data, index) => {
                          return (
                            <Col xs={12} lg={6} key={index}>
                              <StyledRow className="my-1 employee_work_info">
                                <Col sm={12} md={6} className="my-1 text-wrap">
                                  <b className="label_Color">{data.data}: </b>
                                </Col>
                                <Col
                                  sm={12}
                                  md={6}
                                  className="my-1 employess_detail_values text-wrap"
                                >
                                  <b className="label_value_Color">
                                    {data.value}
                                  </b>
                                </Col>
                              </StyledRow>
                            </Col>
                          );
                        })}
                      </Row>
                      <br />
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <p className="employee_detail_headings mb-0">
                          Next Of Kin Details:
                        </p>
                      </div>
                      <Row className="employee_work_info_box">
                        {nextOfKinDetails?.map((data, index) => {
                          return (
                            <Col xs={12} lg={6} key={index}>
                              <StyledRow className="my-1 employee_work_info">
                                <Col sm={12} md={6} className="my-1 text-wrap">
                                  <b className="label_Color">{data.data}: </b>
                                </Col>
                                <Col
                                  sm={12}
                                  md={6}
                                  className="my-1 employess_detail_values text-wrap"
                                >
                                  <b className="label_value_Color">
                                    {data.value}
                                  </b>
                                </Col>
                              </StyledRow>
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  )}
                  {activeTab === "1" && (
                    <DocumentFolders
                      IdentityInfoColumns={IdentityInfoColumns}
                      cvInfoColumns={cvInfoColumns}
                      data={IdentityInfoList}
                      cvList={cvList}
                      filteruser={filteruser}
                      fetchMasterData={fetchMasterData}
                      docLoader={docLoader}
                    />
                    // <Row className="personal_details_box">
                    //   <Spin spinning={isDocumentUpload || isLoadingDocuments}>
                    //     <StyledTable
                    //       className="mb-5"
                    //       dataSource={IdentityInfoList}
                    //       columns={IdentityInfoColumns}
                    //       rowClassName={(record, index) =>
                    //         index % 2 === 0 ? "" : "non-white"
                    //       }
                    //       rowKey="role_id"
                    //       pagination={
                    //         IdentityInfoList.length > 20
                    //           ? paginationOptions
                    //           : false
                    //       }
                    //     />
                    //   </Spin>
                    // </Row>
                  )}
                  {activeTab === "2" && (
                    <>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <b>Education Details:</b>
                        {type === "manage" &&
                          Menulist?.find(
                            (item) =>
                              item?.key === "employee" && item?.permission.edit
                          ) && (
                            <ButtonFilled
                              className="px-1"
                              onClick={() => setUpdateState("educationdetails")}
                            >
                              <Icon name="edit"></Icon>
                            </ButtonFilled>
                          )}
                        {type === "hr" &&
                          Menulist?.find(
                            (item) =>
                              item?.key === "HRResources" &&
                              item?.subMenu?.find(
                                (subItem) =>
                                  subItem?.key === "team" &&
                                  subItem?.permission.edit
                              )
                          ) && (
                            <ButtonFilled
                              className="px-1"
                              onClick={() => setUpdateState("educationdetails")}
                            >
                              <Icon name="edit"></Icon>
                            </ButtonFilled>
                          )}
                      </div>
                      <StyledTable
                        dataSource={filteruser?.educationdetails}
                        columns={EducationColumns}
                        rowClassName={(record, index) =>
                          index % 2 === 0 ? "" : "non-white"
                        }
                        rowKey="role_id"
                        pagination={false}
                      />

                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <b>Professional Certification:</b>
                        {type === "manage" &&
                          Menulist?.find(
                            (item) =>
                              item?.key === "employee" && item?.permission.edit
                          ) && (
                            <ButtonFilled
                              className="px-1"
                              onClick={() =>
                                setUpdateState("professionalcertifications")
                              }
                            >
                              <Icon name="edit"></Icon>
                            </ButtonFilled>
                          )}
                        {type === "hr" &&
                          Menulist?.find(
                            (item) =>
                              item?.key === "HRResources" &&
                              item?.subMenu?.find(
                                (subItem) =>
                                  subItem?.key === "team" &&
                                  subItem?.permission.edit
                              )
                          ) && (
                            <ButtonFilled
                              className="px-1"
                              onClick={() =>
                                setUpdateState("professionalcertifications")
                              }
                            >
                              <Icon name="edit"></Icon>
                            </ButtonFilled>
                          )}
                      </div>
                      <StyledTable
                        dataSource={filteruser?.professionalcertifications}
                        columns={PersenalcertificationColumns}
                        rowClassName={(record, index) =>
                          index % 2 === 0 ? "" : "non-white"
                        }
                        rowKey="role_id"
                        pagination={false}
                      />

                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <b>Practical Training:</b>
                        {type === "manage" &&
                          Menulist?.find(
                            (item) =>
                              item?.key === "employee" && item?.permission.edit
                          ) && (
                            <ButtonFilled
                              className="px-1"
                              onClick={() =>
                                setUpdateState("practicaltrainings")
                              }
                            >
                              <Icon name="edit"></Icon>
                            </ButtonFilled>
                          )}
                        {type === "hr" &&
                          Menulist?.find(
                            (item) =>
                              item?.key === "HRResources" &&
                              item?.subMenu?.find(
                                (subItem) =>
                                  subItem?.key === "team" &&
                                  subItem?.permission.edit
                              )
                          ) && (
                            <ButtonFilled
                              className="px-1"
                              onClick={() =>
                                setUpdateState("practicaltrainings")
                              }
                            >
                              <Icon name="edit"></Icon>
                            </ButtonFilled>
                          )}
                      </div>
                      <StyledTable
                        dataSource={filteruser?.practicaltrainings}
                        columns={PracticalTrainingColumns}
                        rowClassName={(record, index) =>
                          index % 2 === 0 ? "" : "non-white"
                        }
                        rowKey="role_id"
                        pagination={false}
                      />
                    </>
                  )}
                  {activeTab === "3" && (
                    <>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <b>Work Experience:</b>
                        {type === "manage" &&
                          Menulist?.find(
                            (item) =>
                              item?.key === "employee" && item?.permission.edit
                          ) && (
                            <ButtonFilled
                              className="px-1"
                              onClick={() => setUpdateState("workexperience")}
                            >
                              <Icon name="edit"></Icon>
                            </ButtonFilled>
                          )}
                        {type === "hr" &&
                          Menulist?.find(
                            (item) =>
                              item?.key === "HRResources" &&
                              item?.subMenu?.find(
                                (subItem) =>
                                  subItem?.key === "team" &&
                                  subItem?.permission.edit
                              )
                          ) && (
                            <ButtonFilled
                              className="px-1"
                              onClick={() => setUpdateState("workexperience")}
                            >
                              <Icon name="edit"></Icon>
                            </ButtonFilled>
                          )}
                      </div>

                      <StyledTable
                        className="mb-5"
                        dataSource={filteruser?.workexperience}
                        columns={wrokExperenceColumns}
                        rowClassName={(record, index) =>
                          index % 2 === 0 ? "" : "non-white"
                        }
                        rowKey="role_id"
                        pagination={false}
                      />
                    </>
                  )}
                  {activeTab === "4" && (
                    <>
                      <Row gutter={16}>
                        {filteredCategoriesbygender.map((data) => (
                          <>
                            {leaveData &&
                              leaveData?.find(
                                (item) => item[data?.leave_category_short_name]
                              )?.[data?.leave_category_short_name] && (
                                <Col
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  xl={6}
                                  key={data.leave_category_short_name}
                                >
                                  <Spin spinning={loading}>
                                    <LeaveTrackerChart
                                      iconName={LEAVE_ICON[7]}
                                      title={data.leave_category_name.toUpperCase()}
                                      data={
                                        leaveData
                                          ?.find(
                                            (item) =>
                                              item[
                                                data?.leave_category_short_name
                                              ]
                                          )
                                          ?.[
                                            data?.leave_category_short_name
                                          ].map((item) => ({
                                            ...item,
                                            color: item.color,
                                            type: item.type,
                                          })) ??
                                        [] ??
                                        []
                                      }
                                    />
                                  </Spin>
                                </Col>
                              )}
                          </>
                        ))}
                      </Row>
                      <br />
                      <p className="leave_title_content">Leave Approvals:</p>
                      <Row className="personal_details_box">
                        <StyledTable
                          className="mb-5"
                          dataSource={LeaveApprovalData}
                          columns={LeaveApprovalColumns}
                          rowClassName={(record, index) =>
                            index % 2 === 0 ? "" : "non-white"
                          }
                          rowKey="role_id"
                          pagination={
                            LeaveApprovalData.length > 20
                              ? paginationOptions
                              : false
                          }
                        />
                      </Row>
                      <br />
                      <p className="leave_title_content">Leave History:</p>
                      <Row className="personal_details_box">
                        <StyledTable
                          className="mb-5"
                          dataSource={LeaveHistoryData}
                          columns={LeaveHistoryColumns}
                          rowClassName={(record, index) =>
                            index % 2 === 0 ? "" : "non-white"
                          }
                          rowKey="role_id"
                          pagination={
                            LeaveHistoryData.length > 20
                              ? paginationOptions
                              : false
                          }
                        />
                      </Row>
                    </>
                  )}
                  {activeTab === "6" && (
                    <>
                      <div className="d-flex gap-2 mb-3">
                        <big>Year : </big>
                        <DatePicker picker="year" />
                      </div>
                      <Row className="employee_work_info_box">
                        {EmployeeScore.map((data, index) => {
                          return (
                            <StyledRow className="my-1 employee_work_info">
                              <Col sm={12} md={4} className="my-1 text-nowrap">
                                <b className="label_Color">{data.data} </b>
                              </Col>
                              <Col
                                sm={12}
                                md={4}
                                className="my-1 employess_detail_values text-wrap"
                              >
                                <b className="label_value_Color">
                                  {data.value}
                                </b>
                              </Col>
                              <Col
                                sm={12}
                                md={4}
                                className="m-0 p-0 employess_detail_values text-wrap"
                              >
                                <ButtonFilled className="m-0 mb-2">
                                  View details
                                </ButtonFilled>
                              </Col>
                            </StyledRow>
                          );
                        })}
                      </Row>

                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <p className="employee_detail_headings mt-3 mx-0 mb-0">
                          VTP Score:
                        </p>
                      </div>
                      <Row className="employee_work_info_box">
                        {VtpDetails.map((data, index) => {
                          return (
                            <Col xs={12} lg={10} key={index}>
                              <StyledRow className="my-1 employee_work_info">
                                <Col
                                  sm={12}
                                  md={6}
                                  className="my-1 text-nowrap"
                                >
                                  <b className="label_Color">{data.data}: </b>
                                </Col>
                                <Col
                                  sm={12}
                                  md={6}
                                  className="my-1 employess_detail_values text-wrap"
                                >
                                  <b className="label_value_Color">
                                    {data.value}
                                  </b>
                                </Col>
                              </StyledRow>
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  )}
                  {activeTab === "5" && (
                    <Declaration filteruser={filteruser} type="Declaration" />
                  )}
                  {activeTab === "7" && (
                    <>
                      <div className="d-flex gap-2">
                        <big>Year : </big>
                        <DatePicker picker="year" />
                      </div>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <p className="mt-2 mx-0  employee_detail_headings mb-0">
                          Example Group:
                        </p>
                      </div>
                      <Row className="p-0 employee_work_info_box">
                        {ExampleGroupDetails.map((data, index) => {
                          return (
                            <Col xs={12} lg={10} key={index}>
                              <StyledRow className="employee_work_info">
                                <Col
                                  sm={12}
                                  md={5}
                                  className="py-2 text-nowrap graybg"
                                >
                                  <b className="label_Color">{data.data}: </b>
                                </Col>
                                <Col
                                  sm={12}
                                  md={7}
                                  className="py-1 employess_detail_values text-wrap"
                                >
                                  <b className="label_value_Color">
                                    {data.value}
                                  </b>
                                </Col>
                              </StyledRow>
                            </Col>
                          );
                        })}
                      </Row>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <p className="employee_detail_headings mt-3 mx-0  mb-0">
                          Non Chargeable time spent:
                        </p>
                      </div>
                      <Row className="p-0 employee_work_info_box">
                        {TimeSpentDetails?.map((data, index) => {
                          return (
                            <Col xs={12} lg={10} key={index}>
                              <StyledRow className="employee_work_info">
                                <Col
                                  sm={12}
                                  md={5}
                                  className="py-2 text-nowrap graybg"
                                >
                                  <b className="label_Color">{data.data}: </b>
                                </Col>
                                <Col
                                  sm={12}
                                  md={7}
                                  className="py-1 employess_detail_values text-wrap"
                                >
                                  <b className="label_value_Color">
                                    {data.value}
                                  </b>
                                </Col>
                              </StyledRow>
                            </Col>
                          );
                        })}
                      </Row>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <p className="employee_detail_headings mt-3 mx-0  mb-0">
                          Location of work:
                        </p>
                      </div>
                      <StyledTable
                        className="mb-2 mt-2"
                        dataSource={locationOfWorkData}
                        columns={locationOfWorkColumns}
                        rowClassName={(record, index) =>
                          index % 2 === 0 ? "" : "non-white"
                        }
                        rowKey="wfhome"
                        pagination={
                          IdentityInfoList.length > 20
                            ? paginationOptions
                            : false
                        }
                      />
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <p className="employee_detail_headings mt-2 mx-0  mb-0">
                          Compliance Status:
                        </p>
                      </div>
                      <Row className="p-0 employee_work_info_box">
                        <Col xs={12} lg={10} key={index}>
                          <StyledRow className="employee_work_info">
                            <Col
                              sm={12}
                              md={5}
                              className="py-2 text-nowrap graybg"
                            >
                              <b className="label_Color">Independence Form</b>
                            </Col>
                            <Col
                              sm={12}
                              md={7}
                              className="py-2 employess_detail_values text-wrap"
                            >
                              <b className="label_value_Color">Yes/No</b>
                            </Col>
                          </StyledRow>
                          <StyledRow className="employee_work_info">
                            <Col
                              sm={12}
                              md={5}
                              className="py-2 text-nowrap graybg"
                            >
                              <b className="label_Color">
                                Mandatory trainings attended
                              </b>
                            </Col>
                            <Col
                              sm={12}
                              md={7}
                              className="py-2 employess_detail_values text-wrap"
                            >
                              <b className="label_value_Color">0</b>
                            </Col>
                          </StyledRow>
                        </Col>
                      </Row>
                      {ComplianceStatus.map((item) => (
                        <Row className="mt-3 p-0 employee_work_info_box">
                          <Col xs={12} lg={10} key={index}>
                            <StyledRow className="employee_work_info">
                              <Col
                                sm={12}
                                md={5}
                                className="py-2 ext-nowrap graybg"
                              >
                                <b className="label_Color">{item?.key}</b>
                              </Col>
                              <Col
                                sm={12}
                                md={7}
                                className="py-2 employess_detail_values text-wrap"
                              >
                                <b className="label_value_Color">
                                  {item?.value}
                                </b>
                              </Col>
                            </StyledRow>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                  {activeTab === "8" &&
                    userInfo?.userid === "sandeep.khapre@bdo-ea.com" && (
                      <>
                        {/* <div className="d-flex gap-2">
                        <big>Year : </big>
                        <DatePicker picker="year" />
                      </div> */}
                        <br />
                        <div className="mb-2 d-flex justify-content-between align-items-center">
                          <p className="employee_detail_headings mx-0 mt-2  mb-0">
                            Salary & Benefits:
                          </p>
                          {type === "manage" &&
                            Menulist?.find(
                              (item) =>
                                item?.key === "employee" &&
                                item?.permission.edit
                            ) && (
                              <ButtonFilled
                                className="px-1"
                                onClick={() => setUpdateState("salaryInfo")}
                              >
                                <Icon name="edit"></Icon>
                              </ButtonFilled>
                            )}

                          {type === "hr" &&
                            Menulist?.find(
                              (item) =>
                                item?.key === "HRResources" &&
                                item?.subMenu?.find(
                                  (subItem) =>
                                    subItem?.key === "team" &&
                                    subItem?.permission.edit
                                )
                            ) && (
                              <ButtonFilled
                                className="px-1"
                                onClick={() => setUpdateState("salaryInfo")}
                              >
                                <Icon name="edit"></Icon>
                              </ButtonFilled>
                            )}
                        </div>
                        <Row className="p-0 employee_work_info_box">
                          {SalaryAndBenefits.map((data, index) => {
                            return (
                              <Col xs={12} lg={10} key={index}>
                                <StyledRow className="employee_work_info">
                                  <Col
                                    sm={12}
                                    md={5}
                                    className="py-2 text-nowrap graybg"
                                  >
                                    <b className="label_Color">{data.data}: </b>
                                  </Col>
                                  <Col
                                    sm={12}
                                    md={7}
                                    className="py-1 employess_detail_values text-wrap"
                                  >
                                    <b className="label_value_Color">
                                      {data.value}
                                    </b>
                                  </Col>
                                </StyledRow>
                              </Col>
                            );
                          })}
                        </Row>
                        <br />
                        <div className="mb-2 d-flex justify-content-between align-items-center">
                          <p className="employee_detail_headings mx-0 mt-2  mb-0">
                            Banking Information:
                          </p>
                          {type === "manage" &&
                            Menulist?.find(
                              (item) =>
                                item?.key === "employee" &&
                                item?.permission.edit
                            ) && (
                              <ButtonFilled
                                className="px-1"
                                onClick={() => setUpdateState("bankInfo")}
                              >
                                <Icon name="edit"></Icon>
                              </ButtonFilled>
                            )}

                          {type === "hr" &&
                            Menulist?.find(
                              (item) =>
                                item?.key === "HRResources" &&
                                item?.subMenu?.find(
                                  (subItem) =>
                                    subItem?.key === "team" &&
                                    subItem?.permission.edit
                                )
                            ) && (
                              <ButtonFilled
                                className="px-1"
                                onClick={() => setUpdateState("bankInfo")}
                              >
                                <Icon name="edit"></Icon>
                              </ButtonFilled>
                            )}
                        </div>
                        <Row className="employee_work_info_box">
                          {bankInfo?.map((data, index) => {
                            return (
                              <Col xs={12} lg={6} key={index}>
                                <StyledRow className="my-1 employee_work_info">
                                  <Col
                                    sm={12}
                                    md={6}
                                    className="my-1 text-wrap"
                                  >
                                    <b className="label_Color">{data.data}: </b>
                                  </Col>
                                  <Col
                                    sm={12}
                                    md={6}
                                    className="my-1 employess_detail_values text-wrap"
                                  >
                                    <b className="label_value_Color">
                                      {data.value}
                                    </b>
                                  </Col>
                                </StyledRow>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    )}
                </TabPane>
              ))}
            </Tabs>
          </StyledProfileDetails>
          <StyledModal
            title="Profile Pic"
            open={showImageModal}
            onCancel={() => {
              CloseImageModal();
              resetSelectedImage();
            }}
            confirmLoading={confirmLoading}
            footer={[
              <ButtonOutlined
                key="cancel"
                onClick={() => {
                  CloseImageModal();
                  resetSelectedImage();
                }}
              >
                Cancel
              </ButtonOutlined>,
              <ButtonFilled
                key="save"
                onClick={() => {
                  handleUploadProfile();
                }}
                disabled={!selectedImage}
                loading={confirmLoading}
              >
                Set
              </ButtonFilled>,
            ]}
          >
            {selectedImage ? (
              <div>
                <AvatarEditor
                  ref={editorRef}
                  image={objectUrl}
                  width={200}
                  height={200}
                  border={50}
                  color={[255, 255, 255, 0.6]}
                  scale={zoomin}
                  rotate={rotatein}
                  borderRadius={250}
                />
                <div className="editor_div">
                  <label>Zoom:</label>
                  <Slider
                    defaultValue={zoomin}
                    onChange={setZoomin}
                    min={1}
                    max={20}
                  />
                </div>
                <div className="editor_div">
                  <label>Rotate:</label>
                  <Slider
                    defaultValue={rotatein}
                    onChange={setRotatein}
                    min={0}
                    max={360}
                  />
                </div>
                <MainStyledDropzone onDrop={handleDrop} />
              </div>
            ) : (
              <MainStyledDropzone onDrop={handleDrop} />
            )}
          </StyledModal>

          <StyledNewModal
            title="Reason"
            open={reasonopen}
            onCancel={handleReasonCancel}
            footer={[
              <ButtonOutlined key="cancel" onClick={handleReasonCancel}>
                Cancel
              </ButtonOutlined>,
              <ButtonFilled key="save" type="primary" onClick={handleReasonOk}>
                Proceed
              </ButtonFilled>,
            ]}
          >
             <Input.TextArea
                className="content_style"
                placeholder="Enter reason for termination..."
                rows={4}
                value={terminatedReason}
                onChange={handleReasonChange}
            />
          </StyledNewModal>

          <StyledNewModal
            title="Terminating!"
            open={open}
            onCancel={handleCancel}
            footer={[
              <ButtonOutlined key="cancel" onClick={handleCancel}>
                Cancel
              </ButtonOutlined>,
              <ButtonFilled key="save" type="primary" onClick={handleOk}>
                Terminate
              </ButtonFilled>,
            ]}
          >
            <div className="content_style">
              On terminating the employee, their access to company resources
              will be revoked and their performance cannot be tracked.
            </div>
          </StyledNewModal>

          <Modal
            title="Approve leave"
            open={isModalOpen}
            onOk={approveHandleOk}
            onCancel={approveHandleCancel}
            footer={[
              <Button key="back" onClick={approveHandleCancel}>
                No
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={approveHandleOk}
              >
                Yes
              </Button>,
            ]}
          >
            <p>Are you sure you want to approve leave?</p>
          </Modal>

          <RejectReasonModal
            isOpenModal={displayDeclineReasonModal}
            closeModal={closeDeclineReasonModal}
          />
        </>
      )}

      {updateState && (
        <EditProfile
          updateState={updateState}
          setUpdateState={setUpdateState}
          EmployeeDetails={EmployeeDetails}
          pageName="Employee"
        />
      )}
      <StyledDocumentModal
        centered={true}
        visible={isPreviewModalOpen}
        footer={false}
        keyboard={false}
        width="100%"
        height="100vh"
        onOk={() => setIsPreviewModalOpen(false)}
        onCancel={() => setIsPreviewModalOpen(false)}
      >
        <Spin spinning={isLoadingDocuments}>
          {previewType === "jpg" ||
          previewType === "png" ||
          previewType === "jpeg" ||
          previewType === "svg" ? (
            <div className="img">
              <img src={previewURL} className="img_fit" alt="Preview" />
            </div>
          ) : (
            <embed
              title="PDF Viewer"
              src={previewURL}
              type="application/pdf"
              width="100%"
              height="600px"
              className="pdf-viewer-embed px-5"
            />
          )}
        </Spin>
      </StyledDocumentModal>
    </>
  );
};

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  ClientId: getClientid(state),
  departmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  roleList: getRoleList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  userInfo: getUserInfo(state),
  leaveCategoryList: getLeaveCategoryList(state),
  menuList: getMenuList(state),
  userRoleId: getUserRoleId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
      saveProfilePic: setProfilePic,
    },
    dispatch
  );

const StyledNewModal = styled(Modal)`
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-title {
    color: #1f243b !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  .ant-modal-body {
    padding: 0px 17px 30px 24px;
  }
  .ant-modal-header {
    border-bottom: none !important;
    border-radius: 40px 40px 0 0;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-footer {
    border-top: none !important;
  }
  .content_style {
    color: #636980;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
`;
const StyledDocumentModal = styled(Modal)`
  height: 100vh;
  max-width: 80rem;
  padding: 1%;
  width: 100%;

  .ant-modal-content {
    height: 100vh;
    width: 100%;
    box-shadow: none;
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 80rem;
    transform: scale(0.85);
  }

  .iframe {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    transform: scale(0.85);
  }
  .file_fit {
    width: 100%;
    height: 100%;
  }
  .img_fit {
    max-width: 80vw;
    max-height: 100vh;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-header {
    border-bottom: none !important;
    border-radius: 40px 40px 0 0;
  }
  .ant-modal-footer {
    border-top: none !important;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .modal {
    display: flex;
    justify-content: center;
    border-radius: 10px;
  }
  .ant-modal-body > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .editor_div {
    width: 75%;
  }
`;
const StyledProfileDetails = styled.div`
  .terminate {
    margin-left: 20px !important;
  }
  .Icon_style {
    font-size: "20px";
  }
  .StyledImage {
    background-color: white;
    vertical-align: middle;
  }
  .ant-badge .ant-scroll-number-custom-component {
    transform: translate(0%, 0%) !important ;
    bottom: 0px !important;
    top: 89px;
  }
  .styled_col {
    display: flex;
    align-items: center;
    @media (max-width: 428px) {
      flex-direction: column;
    }
  }
  .camera_select_icon {
    font-size: 22px;
  }
  .camera_select_icon rect {
    fill: #f3fefa;
  }
  .Remove_select_icon {
    margin-left: -10px !important;
    margin-right: 6px !important;
    margin-top: -2px !important;
  }
  .Edit_select_icon {
    height: 30px !important;
    width: 29px !important;
    margin-left: -10px !important;
    margin-top: -2px !important;
  }
  .Edit_select_icon rect {
    fill: none;
  }
  .leave_approval_actions {
    display: flex;
    gap: 10px;
  }
  .leave_tracker_container {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 27px 0px #00000017;
    border-radius: 14px;
  }
  .leave_tracker_chart {
    display: flex;
    gap: 1rem;
    @media (max-width: 890px) {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
  .css-1ps7utq {
    margin-bottom: 0 !important;
  }
  .employee_work_info_box {
    width: 100%;
    margin: auto;
    padding: 10px;
    box-shadow: 0px 3px 6px #00000015;
    background: #ffffff 0% 0% no-repeat padding-box;
  }
  .employess_detail_values {
    text-wrap: nowrap;
  }
  .info_identity_container {
    width: 100%;
    margin: auto;
  }
  .personal_details_box {
    width: 100%;
    margin: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
  }
  .employee_work_info {
    border-bottom: 1px solid #f8f8f8;
  }
  .employess_detail_summary {
    display: flex;
    flex-direction: column;
  }
  .employee_detail_headings {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #1f243b;
    margin-left: 10px;
  }
  .leave_title_content {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #1f243b;
  }
  .Broker_profile_flex {
    align-items: center;
    padding: 2rem;
    border-radius: 5px;
  }
  .Broker_profile_box {
    width: max-content;
    flex: unset;
    display: flex;
    align-items: end;
    gap: 0.6rem;
    padding-left: 0;
  }
  .broker_profile_img {
    width: 150px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
  }
  .profile_verified_img {
    width: 20px;
  }
  .broker_name {
    text-align: left;
    font: normal normal bold 24px/30px Circular Std;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .broker_tittle {
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  .common_color_style {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #636980;
    margin-top: 5px;
  }

  .user_details {
    display: flex;
    width: max-content;
    gap: 5px;
    text-align: left;
    font: normal normal bold 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
    p {
      margin-bottom: 0.5rem;
    }
  }
  .contact_details {
    display: flex;
    gap: 0.6rem;
    text-wrap: wrap;
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    color: #ffffff;
    p {
      margin-bottom: 0rem;
    }
  }
  .Engaged {
    font: normal normal medium 14px/20px Circular Std;
    letter-spacing: 0.07px;
    color: #3b3938;
    opacity: 1;
    margin-top: 5px;
  }
  .activity_content {
    text-align: center;
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    color: #3b3938;
    opacity: 1;
  }
  .employee_bond_text {
    text-align: center;
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    color: #3b3938;
    opacity: 1;
  }
  .contact_details {
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    color: #ffffff;
  }
  .contact_det_header {
    font: normal normal bold 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
  .contact_det_content {
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
  .broker_score_hr {
    margin: 0rem;
    background: #00000029;
    color: #00000029;
    opacity: 1;
  }
  .vr {
    display: inline-block;
    align-self: stretch;
    width: 2px;
    height: 26px !important;
    background: #000000;
    color: #000000;
    opacity: 1;
  }
  .credit_box_container {
    display: flex;
    justify-content: end;
    gap: 0.8rem;
    padding-right: 0;
    .credits_box {
      .user_score_title {
        width: 100%;
        text-align: center;
        font: normal normal normal 16px/20px Circular Std;
        letter-spacing: 0px;
        color: #000000;
        opacity: 0.8;
        margin-bottom: 0rem;
        .user_score_status {
          font: normal normal bold 24px/30px Circular Std;
          letter-spacing: 0px;
          color: #3da000;
          opacity: 1;
        }
        .user_working_hour {
          font: normal normal bold 24px/20px Circular Std;
          letter-spacing: 0px;
          color: #000000;
          small {
            font: normal normal normal 16px/20px Circular Std;
            letter-spacing: 0px;
            color: #000000;
          }
        }
      }
    }
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @media (max-width: 900px) {
    .contact_details {
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      gap: 0;
    }
  }
  .credits_box {
    display: flex;
    flex: 1 0 200px;
    height: 150px;
    flex-direction: column;
    text-align: center;
    justify-content: space-evenly;
    background: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    text-align: left;
    h2 {
      font: normal normal bold 36px/46px Circular Std;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 0rem;
    }
    .active_status {
      font: normal normal normal 16px/20px Circular Std;
      letter-spacing: 0px;
      color: #000000;
      opacity: 0.8;
    }
  }
  .reactive_button {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #000000;
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .employess_detail_values {
      text-wrap: wrap;
    }
  }
  .ant-tabs-nav-wrap {
    border-bottom: 2px solid #e2e2e8;
    color: #636980 !important;
  }
  .ant-tabs-tab-active {
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3019;
  }
  .ant-tabs-ink-bar {
    background-color: #000;
  }
  .ant-tabs-tab {
    color: #636980;
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20.83px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
  }
`;

const StyledTable = styled(Table)`
  padding: 0;
  margin-bottom: 0;
  table {
    table-layout: auto !important;
  }
  .ant-table-wrapper {
    margin-bottom: 0;
    border: 2px solid red !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #e2e2e8;

    border-radius: 8px;
  }
  .ant-table-cell {
    height: 50px;
  }
  .ant-table-thead .ant-table-cell {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #1f243b;
  }
  .ant-table-tbody > tr > td {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3019;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }
`;
const StyledAvatar = styled(Avatar)`
  font-size: 3rem;
  height: 120px;
  width: 120px;
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  color: #000;
  .ant-avatar-string {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  .my-1 {
    display: flex;
    word-wrap: break-word;
  }
  .graybg {
    background-color: #f6f6f6;
  }
  .label_Color {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3019;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .label_value_Color {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #1f243b;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
